import React, { Component } from 'react';
import { Header, Footer } from '../layouts/home04/index';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom'

const retailertandc = [
    `<ul>
    <h6>Dear Retail QR Scanner - Version 1.1</h6>
     <li> 
     Interpretation  
     The words with initial letter in capitalized form have meanings as defined herein. 
   </li>
     <h6>1. Definitions : </h6>
     </ul>
     <ul>
         <li><i class="fa fa-circle"></i> "Application" refers to the application program provided by the Company, which you download to the Device from an Application Store or from the official Website of the Company.</li>
         <li><i class="fa fa-circle"></i> "Application Store" refers to digital application distribution platforms including the Apple App Store, the Indus Appstore and the Google Play Store etc.</li>
         <li><i class="fa fa-circle"></i> "Content" refers to the information, including details about sold and unsold lottery tickets, that you can view, access, in the Application. This includes, but is not limited to, ticket status, numbers, and other related data, regardless of the format in which it is presented by the Company.</li>
         <li><i class="fa fa-circle"></i> “Company” (“we”, “us”, or “our”) refers to Charles Technologies Private Limited and includes its associated entities, sister concerns also.</li>
         <li><i class="fa fa-circle"></i> "Device" means any device that can access the Application and includes a computer, mobile phone, digital tablet or any other device/ instruments/gadgets etc.</li>
         <li><i class="fa fa-circle"></i> "Employee" means an individual who is employed by the Company, and is authorized to access the Application for the purpose of carrying out his/her duties and responsibilities for the Company. Employees should access the Application using Company credentials provided by the Company.</li>
         <li><i class="fa fa-circle"></i> "External User" mean any individual or entity who do not fall under the categories of Employee or Retailer and is prohibited from accessing the Application. External Users may include, but are not limited to, contractors, third-party service providers, consumers, or members of the general public.</li>
         <li><i class="fa fa-circle"></i> "Retailer" means a business entity or individual that has entered into a contractual relationship with the Company for the specific purpose of viewing, managing, and/or recording the status of the lottery tickets through the Application.</li>
         <li><i class="fa fa-circle"></i> The Employees or Retailers shall be the users of the Application and shall be referred to as “You”, “Your”, “It”, “He”, “She”, “They”, or “Them” as the context may be required.</li>
         <li><i class="fa fa-circle"></i> "Third-Party Services" refers to any services, content, materials, or links (including data, information, applications and other products services) provided by entities other than the Company, that is accessible or visible to You while using the Application. This includes, but is not limited to, advertisements, external websites, trademarks, copyrights, third-party software, or any other material that originates from sources outside of the Application.</li>
         <li><i class="fa fa-circle"></i> Employees and Retailers are authorized to access the Application solely for conducting business-related activities, including but not limited to, processing transactions, viewing ticket information, managing sales, and engaging in other activities directly related to their role with the Company. Employees and Retailers shall ensure that only their authorized personnel access the Application, and they are responsible for safeguarding their login credentials and preventing unauthorized access by any External User/person.</li>  
    <li>
    These definitions shall mean the same whether it is in singular or plural. 
    </li>

   <h6>2. ACKNOWLEDGMENT</h6>
   <li>This Agreement is solely between You and the Company and not with the Application Store or any other Third-Party Service providers. The Application Store is not a party to this Agreement but it can enforce or seek remedies against You as a third-party beneficiary for Your use of its application or services.</li>
    <li>You also agree to abide by the terms and conditions, privacy policy of the Company. The Company reserves the right to enforce its rights against You for any violation of this Agreement.</li>   
</ul>
  <h6>3. PERMITTED USE AND RESTRICTIONS</h6>
  <ul>
  <li>
  You agree and acknowledge that by downloading/installing/using the Application, 
  </li>
  <li>    
    <strong>(a) You have read and understood this Agreement;</strong>
  </li>
  <li>
    <strong>(b) You represent that You are of legal age to enter into a binding Agreement;</strong>
  </li>
  <li>
    <strong>(c) You accept this Agreement and agree that You are legally bound by its terms or conditions;</strong>
  </li>
  <li>
  <strong>(d) You are either the Employee or Retailer of the Company and have been authorised to access the Application by the Company.</strong>
  </li>
  </ul>
  <li>External Users shall not be granted access and any attempt to bypass these restrictions shall result in immediate denial of access, suspension of any existing accounts and legal action for unauthorized use.</li>
  <li>If any Employee or Retailer is found involved in granting access, use of the Application to External Users, appropriate legal action shall be taken by the Company against the Employees or Retailers.</li>
  </ul>
  <h6>4.  DESCRIPTION OF APPLICATION</h6>
  <li>The Application offers You the ability to view, access, use, update and record the Contents the Application. You are granted permission to track ticket sales, manage inventory and monitor ticket status, ensuring accurate records of all transactions. The Application facilitates the management of lottery tickets, allowing users to engage in real-time updates and maintain comprehensive logs of sales activities. This functionality is available exclusively to authorized users, such as Retailers and/or the Employees, for business purposes.<li>
  <li>You agree and acknowledge that the information and records made available in the Contents of the Application shall be used by You exclusively for the official and intended purposes only. This includes, but is not limited to, processing, analysis and other activities directly related to the Company’s operations. You further acknowledge that this information shall not be used for any other purpose, whether internally or externally, and You shall not share or distribute the Contents without the Company’s prior written approval.</li>
  </ul>,
    <h6>5.  GRANT OF LICENSE</h6>
<ul>
<li>You agree that You have complied with, and shall continue to comply with, all provisions of this Agreement. The Company grants You a limited, non-exclusive right to download, install, run, access, update the Contents and use the Application for the purposes outlined herein, as may be intimated by the Company from time to time.</li>
</ul>
<h6>6. RESTRICTIONS ON USE OF APPLICATION:</h6>
<ul>
<li>You agree that You shall not permit any other External User, directly or indirectly, to:</li>
<li><i class="fa fa-circle"></i>(a) access, use, sell, resell, transfer, assign, copy, publish, transmit, distribute, update the Contents, create derivative works of or otherwise disseminate, exploit or make available any portion or all of the Application;</li>
<li><i class="fa fa-circle"></i> (b) reverse engineer, decompile, modify, translate or disassemble any portion or all of the Application including without limitation any of its underlying ideas, software codes, algorithms or structure, or use any portion of or all of the Application to create new applications, programs, and</li>
<li><i class="fa fa-circle"></i>(c) remove or otherwise modify any trademarks, service names, graphics, designs, logos, page headers, button icons, scripts, commercial markings and brand names of the Company or any Third-Party Service Providers (whether registered or unregistered) appearing in the Application;</li>
<li>You agree to use the Application solely for the official purposes specified in this Agreement or otherwise as may be instructed by the Company in writing from time to time.</li>
</ul>
<h6>7. CONFIDENTIALITY</h6>
<ul>
<li>You undertake to keep all proprietary or confidential information provided by the Company, including but not limited to information on the sale of lottery tickets, all information available in the Application from time to time, software, documentation, trade secrets, business plans, data, or other materials (collectively referred to as "Confidential Information"), confidential and not to disclose or use such information for any purpose other than the intended use as expressly authorized under this Agreement. You shall take all reasonable precautions to protect the confidentiality of the Confidential Information and prevent any unauthorized disclosure.</li>
</ul>
<h6>8. INTELLECTUAL PROPERTY:</h6>
<ul>
<li>The Company retains all rights, title, and interest in the Application including but not limited to the software, documentation, trademarks, logos and any other intellectual property whether registered or not (collectively "Intellectual Property") provided to You under this Agreement. You, as a Retailer or Employee, are permitted to use the Application solely as a licensee and only for the purposes specified in this Agreement. You acknowledge that You have no ownership rights to the Application or on any information created by You while performing Your duties as a Retailer or Employee. The Application and all information available in it shall be solely owned and possessed by the Company. You shall not claim any ownership or rights to it.</li>
<li>You agree to notify the Company immediately if You become aware of any unauthorized use or infringement of the Company’s Intellectual Property. The Company reserves the right to take action against such infringements, at its discretion.</li>
</ul>
<h6>9. INDEMNIFICATION</h6>
<ul>
<li>You agree to indemnify, defend and hold harmless Company and its officers, directors, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, arising from or relating to Your use or misuse of the Application or Your failure to protect the Application against External User(s), which shall constitute Your breach of this Agreement. Furthermore, You agree that Company assumes no responsibility for the content You submit or make available through this Application.</li>
</ul>
<h6>10. INCIDENT REPORTING</h6>
<ul>
<li>The software provided to You "as is" and any use of the software is at Your own risk. As a User of the Application You have the obligation to report such incidents of bugs or errors or any kind of security risk or threat You become aware of while using the Application. You shall immediately report such incidents to Our email support@dearlotteries.app</li>
</ul>
<h6>11. LIMITATION OF LIABILITY AND EXCLUSION OF THIRD-PARTY SERVICE RESPONSIBILITY</h6>
<li>
This Application is provided to You for the official use and the Company shall not be liable for loss or damages or sufferings that You may face in the use of the Application. To the fullest extent permitted by applicable law, in no event shall the Company or its affiliates or sister concerns, or any of its or their respective licensors or service providers, have any liability for damages and/or loss arising from or related to Your use of or inability to use the Application.
</li>
<li>
The Application may contain Third-Party Services including but not limited to materials, contents and links to external websites or services that are not owned or controlled by the Company. The Company does not endorse, guarantee, or assume responsibility for any Third-Party Services or for any damages or losses that may result from viewing, accessing or using such content and services. By using the Application, You acknowledge and agree that the Company shall not be liable for any damages, losses, or issues arising from Your access to or interaction with Third-Party Services.
</li>

<h6>12. TERM AND TERMINATION</h6>
<li>(a) The term of this Agreement commences when You download/install the Application and shall continue in effect until terminated by You or Company as set forth in this Agreement.</li>
<li>(b) Neither the Company nor You are required to issue any formal notice of termination of this Agreement. The Company by removing your access shall terminate this Agreement and You by uninstalling the Application shall terminate this Agreement.</li>
<li>(c) If the Company becomes aware that You have engaged in actions detrimental to the Company or have failed to fulfil Your obligations or breached the terms of this Agreement, resulting in loss and/or damages to the Company, the Company reserves the right to terminate this Agreement and take appropriate actions against You.</li>
<li>(d) The Company may at its sole discretion terminate this Agreement at any time without prior notice if it ceases to support the Application, or for reasons of your breach the terms of this Agreement, or for any business reasons.</li>
<li>(e) Upon termination:</li>
<li>(i) all rights granted to You under this Agreement shall also terminate immediately;</li>
<li>(ii) You shall cease all use of the Application and delete all copies of the Application and Contents from Your Device; and</li> 
<li>(iii) You shall delete or destroy any printed versions or information that You had downloaded from the Application;</li>
<li>(f) Termination of this Agreement shall not limit any of the Company's rights or remedies at law or in equity.</li>
</ul>`,
    `<h6>13. AMENDMENTS</h6>
<li>The Company reserves the right to amend or modify this Agreement at any time at its sole discretion, and Your continued use of the Application constitutes acceptance of the updated terms.</li>
<h6>14. UPDATES TO THE APPLICATION</h6>
<li>The Company may periodically release enhancements, improvements, or updates to the features and functionality of the Application. These updates may include patches, bug fixes, upgrades, and other modifications, which may alter or remove certain features or functionalities. You acknowledge and agree that the Company is under no obligation to: -</li>
<li>(i) provide any updates; or</li>  
<li>(ii) continue supporting or enabling any specific features or functionalities of the Application;</li>
<li>Furthermore, You agree that all updates or modifications shall (i) be considered an integral part of the Application, and (ii) remain subject to the terms and conditions of this Agreement.</li>    
<h6>15. RIGHT TO MODIFY, UPDATE, OR DISCONTINUE THE APPLICATION</h6>
<li>The Company reserves the right to modify, update, or discontinue the availability of the Application on the Website and/or on the Application Store at its Discretion from time to time.</li>
<h6>16. ENTIRE AGREEMENT AND SEVERABILITY</h6>
<li>This Agreement constitutes the entire understanding between You and the Company and supersedes all prior understandings, negotiations, discussions either verbal or written. If any provision of this Agreement is found to be invalid or unenforceable by any competent court of law, the remaining provisions shall remain in full force and effect.</li>
<h6>17. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</h6>
<ul>This Agreement shall be governed by laws of India. The courts at Chennai shall have exclusive jurisdiction to determine any disputes relating to this Agreement.</ul>
<h6>18. CONTACT US</h6>
<ul>If You have any questions about this Agreement, You can contact Us by sending Us an email to: support@dearlotteries.app </ul>
<ul>Our EULA was last updated on 24.12.2024</ul>`,   
    // Add more sections as needed
];

const itemsPerPage = 1;

class Home04 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
        };
    }
   

    handlePageClick = (data) => {
        let selected = data.selected;
        this.setState({ currentPage: selected });
    };

    render() {
        const { currentPage } = this.state;
        const offset = currentPage * itemsPerPage;
        const currentItems = retailertandc.slice(offset, offset + itemsPerPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return (
            <div className="" style={{ backgroundColor: '#fff' }}>       
                 
                 <style>
        {`
          li {
            color: #000;
          }
            .pagination li.active a {
    background-color: #000;
    color: #fff;
    border-color: #fff;
}
    .pagination li a {
color: #000;
    background-color: #fff;
        }
        `}
      </style>
                <section className="section-padding contact">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-heading center-heading text-center mb-60">
                                    <h3 className="heading-title">END USER LICENSE AGREEMENT (EULA)</h3>
                                    <p>Date of Issue: 24th December, 2024</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-sm-12 col-md-12"> 
                                {currentItems.map((text, index) => (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: text }} />
                                ))}
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(retailertandc.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </section>

              
            </div>
        );
    }
}

export default Home04;