import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import Winner from "./components/pages/Winner";
import Appfeatures from "./components/pages/Appfeatures";
import Tickets from "./components/pages/Tickets";
import Contactus from "./components/pages/Contactus";
import TermsandConditions from "./components/pages/TermsandConditions";
import TermsCondition from "./components/pages/TermsandCondition";
import RefundPolicy from "./components/pages/RefundPolicy";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import AboutUs from "./components/pages/AboutUs";
import ErrorPage from "./components/pages/ErrorPage";
import AboutUsDark from "./components/pages/AboutUsDark";
import TermsandConditionDark from "./components/pages/TermsandConditionDark";
import RetailerTandC from "./components/pages/RetailerTandC";
import RetailerPolicy from "./components/pages/RetailerPolicy";
import RetailerEula from "./components/pages/RetailerEula";

const router = createBrowserRouter([
  { path: "/", element: <LandingPage />, errorElement: <ErrorPage /> },
  { path: "/winner", element: <Winner /> },
  { path: "/appfeatures", element: <Appfeatures /> },
  { path: "/ticketsviews", element: <Tickets /> },
  { path: "/contactus", element: <Contactus /> },
  { path: "/TermsandConditions", element: <TermsandConditions /> },
  { path: "/RefundPolicy", element: <RefundPolicy /> },
  { path: "/PrivacyPolicy", element: <PrivacyPolicy /> },
  { path: "/AboutUs", element: <AboutUs /> },
  {path: "/AboutUsDark", element: <AboutUsDark />},
  { path: "/RetailerTandC", element:<RetailerTandC/>},
  { path: "/RetailerPolicy", element:<RetailerPolicy/>},
  { path: "/RetailerEula", element:<RetailerEula/>},

  /*For Mobile View Link*/
{path: "/TermsandConditionDark", element: <TermsandConditionDark /> },
  { path: "/TermsandCondition", element: <TermsCondition /> },
  { path: "/AboutUs", element: <AboutUs /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
