import React, { Component } from 'react';
import { Header, Footer } from '../layouts/home04/index';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom'

const retailertandc = [
    `<ul>
    <h6>Dear Retail QR Scanner - Version 1.1</h6>
    <li>These Terms and Conditions ("Terms") set out the rules and procedures that apply when you access and/or use our Application. We advise you to also read our Privacy Policy and End User License Agreement before accessing the Application, creating an Account, and using the QR Scanning feature.  
     </li>
     <li> 
     These Terms, Privacy Policy and End User License Agreement constitute the entire agreement between You and Us. By accessing and using the Application, creating and maintaining an Account with Us, and using the QR Scanning feature, You expressly agree to be bound by the same. 
   </li>
     <h6>1. Definitions : </h6>
     <li>For the purposes of this Terms & Conditions:<li>
     </ul>
     <ul>
         <li><i class="fa fa-circle"></i> “Account” means a unique account created by/for You to access and use Our Application, specifically for scanning QR codes. </li>
         <li><i class="fa fa-circle"></i> ”Application” refers to the Application program provided by the Company, which You download to the Device from an Application Store or from the official Website of the Company.</li>
         <li><i class="fa fa-circle"></i> ”Application Store” refers to digital application distribution Platforms including the Apple App Store, the Indus Appstore and the Google Play Store etc. </li>
         <li><i class="fa fa-circle"></i> ”Company” (“We”, “Us”, or “Our”) refers to Charles Technologies Private Limited and includes its associated entities, sister concerns also. </li>
     
  <li>
    <strong>Device:</strong> means any Device that can access the Application and includes a computer, mobile phone, digital tablet or any other device/ instruments/gadgets etc.
  <li>
  <li>
    <strong>Employee:</strong>means an individual who is employed by the Company, and is authorized to access the Application for the purpose of carrying out his/her duties and responsibilities for the Company. Employees should access the Application using Company credentials provided by the Company. 
  <li>
  <li>
    <strong>External User:</strong> means any individual or entity that does not fall under the categories of Employee or Retailer and is therefore prohibited from accessing the App. External Users may include, but are not limited to, contractors, third-party service providers, consumers, or members of the general public.
  <li>
  <li>
    <strong>Platform:</strong> refers to Mobile Application.
  <li>
  <li>
    <strong>Retailer:</strong> means a business entity or individual that has entered into a contractual relationship with the Company for the specific purpose of viewing, managing, and/or recording the status of the lottery tickets through the Application.
  <li>
  <li>
    <strong>Employees:</strong> The Employees or Retailers shall be the users of the Application and shall be referred to as “You”, “Your”, “User”, “It”, “He”, “She”, “They”, or “Them” as the context may be required.
  <li>
   <strong>Third Party:</strong> refers to any individual, organization, or entity, other than Company, who may have access to Your Personal Data, either through a legal agreement or as part of a specific business relationship, but does not include the Company and its Employees.
  <li>
   <strong>Website:</strong> refers to the online site operated by the Company, accessible through the internet, which provides information, and functionalities related to the Company and its operations, including access to the Application and other resources offered by the Company.
  <li>
  <h6>2. DESCRIPTION OF APPLICATION</h6>
  <li> 
   “Dear Retail QR Scanner” offers authorized Users the ability to view, access, and record the sale of both sold and unsold lottery tickets within the Application. Users are granted permission to track ticket sales, manage inventory, and monitor ticket status, ensuring accurate records of all transactions. The Application facilitates the management of lottery tickets, allowing users to engage in real-time updates and maintain comprehensive logs of sales activities. This functionality is available exclusively to authorized users, such as Retailers and/or the Employees, for business purposes.
  <li>
  <li> 
   You agree and acknowledge that the information and records made accessible to You shall be used exclusively for the official and intended purposes of the Company. This includes, but is not limited to, processing, analysis, and other activities directly related to the Company's operations. You further acknowledge that this information shall not be used for any other purpose, whether internal or external, without explicit approval of the Company, and shall not be shared or distributed for any unauthorized use as mentioned under this Terms. 
  <li>
   

</ul>
  <h6>3. ACCOUNT REGISTRATION:</h6>
  <ul>
  <li>    
    <strong>a)3.1 Eligibility:</strong> Only Employees and Retailers are authorized to access this Application. External Users, or any individuals not specifically mentioned herein, are prohibited from using the Application.
  <li>
  <li>
    <strong>b)3.2 General:</strong> In order to open, access, use Your Account and its features, We require certain personal details from You. By providing these details, You agree to furnish accurate information to the best of Your knowledge and in good faith. It is Your responsibility to ensure that all information provided is truthful, complete, and up to date. Any misrepresentation or failure to provide accurate details shall result in the inability to use, access, open and/or maintain Your Account.
  <li>
  <li>
    We shall also request additional information to verify the details You have provided from time to time at the sole discretion of the Company. We shall take all reasonable steps necessary to keep Your Account secure and comply with any applicable legal requirements. All information You share with us falls under the protection of Our Privacy Policy, which You agree to have accessed and read by sharing Your data. In essence, you trust us to hold and process Your information responsibly, guided by the principles outlined in that Policy. If You have any query or objection to sharing information, please reach out to Our customer service email Id support@dearlotteries.app  for clarifications. <li>
  <li>
    <strong>c)3.3 How to Register? - Account Creation:</strong>
  <li>
  </ul>
  <ul>
    <li><i class="fa fa-circle"></i>One User can register one Account only.</li>
    <li><i class="fa fa-circle"></i>New users need to click on the “Sign Up" button and provide necessary information like name, email, phone number, date of birth, and other credentials given by the Company and create a password.</li>
  </ul>

  <h6>4. ACCOUNT ACCESS & RESPONSIBILITY:</h6>
  <li>For the purposes of these Terms & Conditions:<li>
  <ul>
    <li><i class="fa fa-circle"></i> Accessing QR Scanning functionalities:  After successfully opening Your Account, You will gain the ability to utilize the QR scanning feature.</li>
    <li><i class="fa fa-circle"></i> Personal Responsibility: You should maintain the confidentiality and security of Your password and account details. Take all necessary precautions to prevent unauthorized access. You agree that the Company will bear no liability for damages or losses resulting from Your negligence or other lapses in security at Your end. Furthermore, You shall be solely responsible and liable to the Company for any damages or losses arising from such negligence or security breaches.</li>
    <li><i class="fa fa-circle"></i> Unauthorized Access: While we commit to investigating suspicious activities, any unauthorized use due to the unintended disclosure of Your password remain the responsibility of You.</li>
    <li><i class="fa fa-circle"></i> Password Recovery: Forgot Your password? No cause for concern. Simply follow the Platform's prescribed reset instructions for a seamless recovery process.</li>
  </ul>`,
    `<h6>5. PLATFORM ACCESS AND USE:</h6>
<ul>
<li><i class="fa fa-circle"></i> You agree to indemnify Us for any wrongful use. You are advised to check Your legal right to use Our Application to avoid any jurisdictional issues.</li>
<li><i class="fa fa-circle"></i> The content on the Platform is protected by intellectual property laws, and You shall not reproduce or reuse it without Our written approval.</li>
<li><i class="fa fa-circle"></i> Unauthorized reverse engineering or decompiling of associated software is strictly prohibited.</li>
</ul>
<h6>6. RESTRICTIONS ON USE OF APPLICATION:</h6>
<ul>
<li>You will use the Application only for purposes expressly permitted by this Terms and Conditions of the Application.</li>
<li>You agree and undertake that, You shall not:</li>
<li><i class="fa fa-circle"></i>Duplicate the Application or any of the Materials contained therein;</li>
<li><i class="fa fa-circle"></i> Create derivative works based on the Application or any of the Materials contained therein;</li>
<li><i class="fa fa-circle"></i>Use the Application or any of the Materials contained therein for any public display, public performance, etc.</li>
<li><i class="fa fa-circle"></i>Re-distribute the application or any of the Materials contained therein;</li>
<li><i class="fa fa-circle"></i>Remove any copyright or other proprietary notices from the application or any of the Materials contained therein;</li>
<li><i class="fa fa-circle"></i>Circumvent any encryption or other security tools used anywhere on the Application (including the theft of usernames and passwords or using another person's username and password in order to gain access to the Application);</li>
<li><i class="fa fa-circle"></i>Use any data mining, bots, or similar data gathering and extraction tools on the Application;</li>
<li><i class="fa fa-circle"></i>Cause an unreasonable or disproportionately large load on the Application or its network infrastructure;</li>
<li><i class="fa fa-circle"></i>Decompile, reverse engineer, modify or disassemble the Application or its components;</li>
<li><i class="fa fa-circle"></i>Sell, rent, lease, license, sublicense, transfer, distribute, re-transmit, time-share, or otherwise assign to any Third Party, the Materials or any of Your rights to access and use the Materials;</li>
<li><i class="fa fa-circle"></i>Access the application from any location where it is not permissible to access lottery.</li>
</ul>
<h6>7. INCIDENT REPORTING:</h6>
<ul>
<li>The software provided to You "as is" and any use of the software is at Your own risk. As a User of the Application You have the obligation to report such incidents of bugs or errors or any kind of security risk or threat You become aware of while using the Application. You shall immediately report such incidents to corresponding email support@dearlotteries.app </li>
</ul>
<h6>8. CONFIDENTIALITY:</h6>
<ul>
<li>You undertake to keep any proprietary or confidential information provided by the Company, including but not limited to information on the sale of lottery tickets, all information available in the Application from time to time, business plans, software, documentation, trade secrets, business plans, data, or other materials (collectively referred to as "Confidential Information"), confidential and not to disclose or use such information for any purpose other than the intended use as expressly authorized under this Term. You shall take all reasonable precautions to protect the confidentiality of the Confidential Information and prevent any unauthorized disclosure.</li>
</ul>
<h6>9. INTELLECTUAL PROPERTY:</h6>
<ul>
<li>The materials, such as text, graphics, photographs, video and audio clips, music, soundtracks, icons, trademarks, data, animation, images, downloadable materials, data compilations and software, accessible from the Application, and any other World Wide Web site owned, operated, licensed, or controlled by the Company is the proprietary information and valuable intellectual property (“Materials”) of Us or the party that provided the Materials to Us.</li>
<li>We or the party that provided the Materials to Us retains all right, title, ownership and interest in the Materials. You agree not to copy, distribute, modify, or transmit any of the Materials, and not to alter any content of it. The Company also retains all rights to the Application, including its software, trademarks, and intellectual property. You are granted a limited use of the Application solely for the specified purposes and acknowledge that you have no ownership rights to the Application or any information created during your duties as a Retailer or Employee.</li>
</ul>
<h6>10. LIMITATION OF LIABILITY:</h6>
<ul>
<li>This Application is provided to You for the official use only and as such the Company shall not be liable for loss or damages or sufferings that You may face in the use of the Application. In no event shall the Company or its affiliates, or any of its or their respective licensors or service providers, have any liability for damages arising from or related to Your use of or inability to use the Application.</li>
</ul>
<h6>11. TERMINATION, VARIATION, OR SUSPENSION OF YOUR ACCOUNT:</h6>
<li>
We reserve the right, at Our absolute discretion, to terminate, vary, or suspend access to Your Account. This shall occur immediately and without prior notification if:</li>
<li>
<i class="fa fa-circle"></i> Any information provided to Us is found to be fraudulent, false, inaccurate, incomplete, or outdated.</li>
<li>
<i class="fa fa-circle"></i>Based on the request of law enforcement agency of the competent jurisdiction or an order from the court of competent jurisdiction.</li>
<li>
<i class="fa fa-circle"></i> We cease to provide the Application or other functionalities or access to the Platform for any reason.</li>
<li>
<i class="fa fa-circle"></i>Your role with the Company as Employee or Retailer is terminated or expires.</li>
<h6>12. INDEMNIFICATION</h6>
<li>You agree to indemnify, defend and hold harmless Company and its officers, directors, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including attorneys' fees, arising from or relating to Your use or misuse of the Application or Your failure to protect the Application against External User(s) or any action of Yours resulting in third party claims, which shall constitute Your breach of this Agreement.</li>
</ul>`,
    `<h6>13. MODIFICATIONS</h6>
<li>We reserve the right to change, modify, or remove the contents of the Application at any time or for any reason at Our sole discretion without notice. However, We have no obligation to update any information on Our Website and/or Application. We also reserve the right to modify or discontinue all or part of the Application without notice at any time. We shall not be liable to You or any Third Party for any modification, suspension, or discontinuance of the Application. You are also advised to revisit this Terms regularly.</li>
<h6>14. ENTIRE TERMS AND SEVERABILITY</h6>
<li>This Terms constitutes the entire understanding between the User and the Company with respect to the Terms and Conditions hereof and supersedes any prior understandings, negotiations, discussions either verbal or written. If any provision of this Terms is found to be invalid or unenforceable by any competent court of law, the remaining provisions will remain in full force and effect.</li>
<h6>15. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</h6>
<li>This Terms shall be governed by laws of India. The courts at Chennai shall have exclusive jurisdiction to determine any disputes relating to this Terms.</li>
<h6>16. CONTACT US</h6>
<ul>
<li>Please contact Us regarding by emailing Us on support@dearlotteries.app. You can also get in touch with Us through Our customer service +91 8489925481</li>
<li>Please refer to Our End User License Agreement at https://dearlotteries.app/RetailerEula</li>
<li>The Platform is owned and operated by Charles Technologies Private Limited. Customer Service Centre can be contacted on +91 8489925481 or by sending an email to support@dearlotteries.app </li>
<li>Our Terms and Conditions was last updated on 24.12.2024</li>
</ul>
PRIVACY POLICY :</h6>
<ul>
      <li>
        In order to provide You with access to our services, we collect and process some
        of your personal information. Please refer to our Privacy Policy at
        <a href="https://dearlotteries.app/RetailerPolicy" target="_blank" rel="noopener noreferrer">
          https://dearlotteries.app/RetailerPolicy
        </a> which contains details on the types
        of information collected, and how this information is used.
        
      </li>
      <li>
      The Platform is owned and operated by Charles Technologies Private Limited.
        Customer Service Centre can be contacted on <a href="tel:8489925481"> +91 8489925481 </a>or by sending an
        email to <a href="mailto:support@dearlotteries.app">support@dearlotteries.app</a>
      </li>
      <li>
      Grievance Officer Mr. Shree Vishnu Thangamuthu can be reached at email:<a href="mailto:support@dearlotteries.app"> support@dearlotteries.app </a>.
      </li>

    </ul>`,

   
    // Add more sections as needed
];

const itemsPerPage = 1;

class Home04 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
        };
    }
   

    handlePageClick = (data) => {
        let selected = data.selected;
        this.setState({ currentPage: selected });
    };

    render() {
        const { currentPage } = this.state;
        const offset = currentPage * itemsPerPage;
        const currentItems = retailertandc.slice(offset, offset + itemsPerPage);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        return (
            <div className="" style={{ backgroundColor: '#fff' }}>       
                 
                 <style>
        {`
          li {
            color: #000;
          }
            .pagination li.active a {
    background-color: #000;
    color: #fff;
    border-color: #fff;
}
    .pagination li a {
color: #000;
    background-color: #fff;
        }
        `}
      </style>
                <section className="section-padding contact">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="section-heading center-heading text-center mb-60">
                                    <h3 className="heading-title">TERMS AND CONDITIONS</h3>
                                    <p>Date of Issue: 24th December, 2024</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-sm-12 col-md-12"> 
                                {currentItems.map((text, index) => (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: text }} />
                                ))}
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(retailertandc.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </section>

              
            </div>
        );
    }
}

export default Home04;