import React, { Component } from 'react';

class Home04 extends Component {
    
    render() {
        const data = [
            {
              id: 1,
              personalData: 'Full name',
              purpose: 'For registration in the Application and identification and verification process.'
            },
            {
              id: 2,
              personalData: 'Address',
              purpose: 'For registration in the Application and identification and verification process.'
            },
            {
              id: 3,
              personalData: 'Mobile number',
              purpose: 'For registration in the Application, identification and verification process. '
            },
            {
              id: 4,
              personalData: 'Email address',
              purpose: 'For identification and communication purposes. We also use Your email for sending of official business communication',
            },
            {
              id: 5,
              personalData: 'Date of Birth',
              purpose: 'For registration in the Application and identification process.'
            },
            {
              id: 6,
              personalData: 'IP address',
              purpose: 'To verify which location, You as a user is connecting from. It is further used for preventing fraud and improper use of Our systems of different types.'
            },
            {
              id: 7,
              personalData: 'Identity verification documents (Aadhaar and PAN details)',
              purpose: 'For registration in the Application, identification and verification process.',
            },
            {
              id: 8,
              personalData: 'Other type of information that you provide to us by filling in forms on the website email or web chat or telephone or via mobile application.',
              purpose: 'To manage Your Account, offer customer support, communicate updates, gather feedback, and ensure compliance with legal and regulatory requirements.'
            },
            {
              id: 9,
              personalData: 'Your responses to surveys or customer research that we carry out from time to time, Details of the transactions you carry out with us, whether via the Website or other means of communication.',
              purpose: 'For improving Our services.'  
            },
            {
              id: 10,
              personalData: 'Camera and Gallery access',
              purpose: 'When You scan a QR code using the Application, We collect detailed data to enhance Your experience and provide value to the service that the Company provides. This information shall include but not limited to location data, timestamp, etc.'
            },
            {
              id: 11,
              personalData: 'User information',
              purpose: 'We shall collect Your Personal Data and information that is provided by You or Your organization to facilitate business operations. This data is essential for verifying and customizing the Application and to allow access to authorized usage. The type of user information that We collect shall include but not limited to Personal Identifiable Information (PII), role of Employee, business information of Retailers, etc.'
            },
            {
              id: 7,
              personalData: 'Device and technical information',
              purpose: 'In addition to the QR code scans and user information We shall also collect Device- specific data to improve the functionality and security of the Application. These shall include but not limited to the Device information including Device type, operating system, network and Usage Data, etc.'
            },
            {
              id: 7,
              personalData: 'Customer Support',
              purpose: 'We use Your information to address any technical issues You may face, respond to Your support requests, analyze crash reports, and enhance the Application’s functionality. With Your explicit consent, We may share Your information with a Third-Party expert to assist in resolving support-related issues.'
            },
            {
              id: 7,
              personalData: 'For safety and security',
              purpose: 'We use the information that You provide and information about You to verify Accounts and activities and also take necessary actions to detect, respond and prevent any potential or threating security incidents and to monitor the Application against other malicious, deceptive activities on the Application.'
            },
          
          ];
          return (
            <div className="" style={{ backgroundColor: '#fff' }}>       
                 
            <style>
   {`
     li {
       color: #000;
     }
       .pagination li.active a {
background-color: #000;
color: #fff;
border-color: #fff;
}
.pagination li a {
color: #000;
background-color: #fff;
   }
   `}
 </style>
              <section className="section-padding contact light-bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Privacy Policy </h3>
                                <p>Dear Retail QR Scanner - Version 1.1, 24
                                th December 2024</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-sm-12 col-md-12">
                       
<ul>
    <li>This Privacy Policy (“Policy”) explains how we collect, protect and use the personal data and information. We are committed to ensure that any personal data and information supplied by our users or otherwise generated by our business activities is collected and processed fairly and lawfully. The End User License Agreement shall form part of this policy, terms of use and shall collectively govern your responsibility in using our application.</li>
<h6>DEFINITIONS</h6>
<ul>
    <li>
    “Account“ means a unique account created by/for You to access and use Our Application, specifically for scanning QR codes. 
    </li>
    <li>
    “Application“ refers to the Application program provided by the Company, which you download to the Device from an Application Store or from the official Website of the Company.   
    </li>
    <li>
    “Application Store“ refers to digital application distribution Platforms including the Apple App Store, the Indus Appstore and the Google Play Store etc.  
    </li>
    <li>
    “Company“ (“We”, “Us”, or “Our”) refers to Charles Technologies Private Limited and includes its associated entities, sister concerns also.   
    </li>
    <li>
    “Cookies“ refers to small text files containing information which are sent to and stored on Your computer, smartphone or other device when you access a Website and/or Application, to enhance User experience, track usage patterns and remember settings or user preferences.   
    </li>
    <li>
    “Device“ means any Device that can access the Application and includes a computer, mobile phone, digital tablet or any other device/ instruments/gadgets etc.  
    </li>
    <li>
    “Employee“ means an individual who is employed by the Company, and is authorized to access the Application for the purpose of carrying out his/her duties and responsibilities for the Company. Employees shall access the Application using Company credentials provided by the Company. 
    </li>
    <li>
    “Personal Data“ refers to any information that can be used to identify an individual, either directly or indirectly, including but not limited to names, contact details, identification numbers etc. 
    </li>
    <li>
    “Platform“ refers to Mobile Application. 
    </li>
    <li>
    “Retailer“ means a business entity or individual that has entered into a contractual relationship with the Company for the specific purpose of viewing, managing, and/or recording the status of the lottery tickets through the Application. 
    </li>
    <li>
    The Employees or Retailers shall be the users of the Application and shall be referred to as “You”, “Your”, “User”, “It”, “He”, “She”, “They”, or “Them” as the context may be required. 
    </li>
    <li>
    “Sensitive Data“ refers to any personal information that requires special protection due to its nature, such as health information, racial or ethnic origin, political opinions, religious beliefs, or other data classified as sensitive under applicable data protection laws.   
    </li>
    <li>
    “Third Party“ refers to any individual, organization, or entity, other than Company, who may have access to your Personal Data, either through a legal agreement or as part of a specific business relationship, but does not include the Company and its Employees.    
    </li>
    <li>
    “Website“ refers to the online site operated by the Company, accessible through the internet, which provides information, and functionalities related to the Company and its operations, including access to the Application and other resources offered by the Company.   
    </li>
    <li>
    “Usage Data“ refers to data collected automatically, either generated by the use of the Platform or from the Platform infrastructure itself.  
    </li>
</ul>
<h6>SUMMARY</h6>
<li>
We will process Your Personal Data as outlined in this Policy to provide Our services. By agreeing to Our Privacy Policy, You consent to the collection and use of Your information in accordance with this Policy, unless You opt out or are excluded, as specified in this Policy.
</li>
<li>
We will store Your Personal Data for purposes including but not limited to financial records, transaction data, for prevention of fraud and other suspicious activities. In accordance with applicable laws and regulations, We shall also be required to retain certain Personal Data for compliance purposes, such as for auditing, legal reporting, and safeguarding the integrity of Our services. Additionally, if required by law We shall disclose such relevant Personal Data to the appropriate legal or regulatory authorities. 
</li>
<li>
This Policy is a part of our Terms and Conditions (“Terms”) and the End User License Agreement when You use this Application. By using Our Application, You agree to the conditions outlined in these documents. We encourage You to read the Terms and Policy, End User License Agreement carefully to fully understand how Your Personal Data and information is handled. 
</li>

<h6>2. PURPOSE</h6>
<li>
This Privacy Policy outlines how We handle the information and data You provide to Us when using the Application. We will process any Personal Data and information provided to Us or otherwise held by Us relating to You in the manner set out in this Policy. Information may be provided via the (www.___________________)Website, Application, email, mobile Platforms, telephone calls, live chat or by any other means. By submitting Your information to Us and using Our Website, Application, mobile Platform, You confirm Your consent to Our use of Your Personal Data as set out in this Policy. If You do not agree with the terms of this Policy, You shall not use the Website/Application. 
</li>
<li>
We are committed to adhering with the applicable laws in handling of Personal Data in order to protect and process Your Personal Data in a lawful and responsible manner. We are compliant to “The Digital Personal Data Protection Act, 2023” and other applicable laws in protecting Your privacy under this Policy.  
</li>

<h6>3. PERSONAL DATA COLLECTED AND PROCESSED </h6>
<li>The data and information shall be collected from You in order to provide You with access and use of this Application. The collection of Your Personal Data and information is needed for You to successfully access and use the features of this Application including but not limited to Account creation, camera access for taking photographs and scanning QR and managing activities within the Application. The data We gather include, but are not limited to the following:</li>
<table className="data-table">
      <thead>
        <tr>
          <th>Sl.No.</th>
          <th>Personal data used</th>
          <th>Purpose</th>
        </tr>
      </thead>
      <tbody>
        {data.map(row => (
          <tr key={row.id}>
            <td>{row.id}</td>
            <td>{row.personalData}</td>
            <td>{row.purpose}</td>
          </tr>
        ))}
      </tbody>
    </table>
<h6>5. DATA RETENTION </h6>
<li>Your Personal Data and information shall be retained for as long as You remain an Employee or Retailer of the Company and Your continued use of the Application and shall continue to be stored for the duration of Your association with the Company. Unless You provide Us with a written request to delete Your Personal Data, We shall continue to retain such Personal Data and information in Our records. In the event that You cease to be associated with the Company, We shall retain Your Personal Data unless Your submit a written request to delete such Personal Data. Should We be required to retain Your Personal Data beyond the termination of Your association with the Company, We will do so in accordance with the relevant legal and regulatory requirements.</li>
<h6>6. YOUR RIGHTS </h6>
<ul>
<li>
You shall have the right to access, rectify and erase Your Personal Data with Us or delete Your entire Account as well, subject to your association with the Company. In case of deletion of Account, all Personal Data available with Us will be deleted in the Account according to the terms and conditions specified under this Policy. You shall obtain expert legal advice on Your own on how to protect Your data.  
</li>
<li>
  Your rights are subject to applicable law. As such if there is any legal obligation that requires Us to retain, keep or handle or share Your Personal Data, then We shall comply with the applicable law and the directions of the authorities.
</li>
<li>
You have the right to access and rectify Your Personal Data and information We have collected. This right includes:  
</li>
<li>
<i class="fa fa-circle"></i> Confirmation of the accuracy of the Personal Data We have collected,   
</li>
<li>
<i class="fa fa-circle"></i> Request the deletion or rectification of Personal Data that is inaccurate,    
</li>
<li>
<i class="fa fa-circle"></i> Provide details as to the use of Your Personal Data and information   
</li>
<li>
<i class="fa fa-circle"></i> Prohibit future use of Your Personal Data for marketing purposes. 
</li>
</ul>
<h6>7. MARKETING</h6>
<li>
We intend to provide the best possible experience to You, (the Employees and the Retailers). Hence, We always strive to improve Our services to You. Therefore, We will contact You, from time to time and only with Your consent, with campaigns based on Your pre-requisites and behavior on Our site/ Application. If You are not interested in Our marketing communications, please send an email to support@dearlotteries.app 
</li>
<h6>8.  INFORMATION SHARING AND DISCLOSURE</h6>
<li>We may share Your Personal Data with the following agencies if and when required: </li>
<li>
<i class="fa fa-circle"></i>  Law enforcement agencies, courts, regulators and other authorities. 
</li>
<li>
  <i class="fa fa-circle"></i>  Fraud prevention agencies. 
</li>
<li>
  <i class="fa fa-circle"></i>  Identity verification agencies.
</li>
<li>
  <i class="fa fa-circle"></i>  Payment processing agencies.
</li>
<li>
  <i class="fa fa-circle"></i>  Individuals and Organizations that introduce(d) You to Us. 
</li>
<li>
  <i class="fa fa-circle"></i>  Third Parties / referrals You ask Us (or permit Us) to share Your data with. 
</li>
<li>
  <i class="fa fa-circle"></i>  Marketing service providers.  
</li>
<li>
  <i class="fa fa-circle"></i>  Other organizations that help Us understand and process data in a secure and standardized manner. 
</li><li>
  <i class="fa fa-circle"></i>  To any Third Party which approaches Us with the order of a competent court. 
</li>
<li>Data Processing of Your Personal Data and information may be undertaken by any entity within the territorial limits of India, which may use a Third Party to fulfill such data processing needs. In case, where Your Personal Data is transferred outside of India, We shall take all reasonable steps to ensure that Your Personal Data and information are treated securely and in accordance with this Policy as well as complies with all legal requirements associated with such transfer. We do not sell Personal Data to Third Parties. However, Your Personal Data may be transferred if the Company is acquired by a Third Party, merged with a Third Party or if We sell/transfer all or substantially all of its assets, or otherwise to a Third Party, associate entities or sister concerns. In this event, We shall not be obligated to inform You prior to affecting such transfer of the Personal Data.</li>

<h6>9. MODIFICATIONS</h6>
<li>We reserve the right to change, modify, or remove the contents of the Application at any time or for any reason at Our sole discretion without notice. However, We have no obligation to update any information on Our Website and/or Application. We also reserve the right to modify or discontinue all or part of the Application without notice at any time. We shall not be liable to You or any Third Party for any modification, suspension, or discontinuance of the Application. You are also advised to revisit this Privacy Policy regularly.</li>
<h6>10. INTERRUPTIONS</h6>
<li>
We cannot guarantee that the Application will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Application, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Application at any time or for any reason without notice to You. You agree that We have no liability whatsoever for any loss, damage, or inconvenience caused by Your inability to access or Use the Application during any downtime or discontinuance of the Application. If you have any issues with respect to the Application you may contact Mobile: +91 8489925481 & Email: support@dearlotteries.app. Nothing in this Policy will be construed to obligate Us to maintain and support the Application or to supply any corrections, updates, or releases in connection therewith. 
</li>
<h6>11. SENSITIVE DATA AND DATA OF CHILDREN AND MINORS</h6>
<li>Sensitive Personal Data such as information on health, ethnic origin, etc. are not stored by Us. However, We may be required to store certain Sensitive Data if necessary to comply with legal or regulatory obligations specific to the operation of the Application, including security or fraud prevention requirements as allowed under the law.</li>
<li>Minors or children are strictly not permitted by Us, to use Our Application and We do not store any of their Personal Data.</li>
<h6>12. USE OF COOKIES</h6>
<li>By using Our Website/Application and registering an Account, You acknowledge and agree that We use Cookies in Your Devices. Cookies are used to collect information including the type of browser You use and Your IP number. This information is collected for security reasons of the Company’s business. Cookies are useful because they allow a Website/ Application to recognize a User's Device, enabling a more personalized and efficient experience. By using Cookies, We can improve functionality, and track usage patterns to optimize the performance of the Application. We have taken all possible efforts for no unnecessary use of Cookies.</li>
<li>To delete any Cookies already stored on Your computer, mobile, or any other Device that You access the Application from or stop the Cookies that keep track of Your browsing patterns on the Website/ Application, You can do so by deleting Your existing Cookies and/or altering Your browser's privacy settings.</li>
<h6>13. DATA SECURITY</h6>
<li>
We prioritize data security with robust safeguard mechanism and breach notification protocols. We comply with the Digital Personnel Data Protection Act, 2023. We ensure User Personal Data is protected to the highest standards and in line with government regulations.</li>
<h6>14. ENTIRE POLICY AND SEVERABILITY</h6>
<li>This Policy constitutes the entire understanding between You and the Company and supersedes all prior understandings, negotiations, discussions either verbal or written. If any provision of this Policy is found to be invalid or unenforceable by any competent court of law, the remaining provisions shall remain in full force and effect.
</li>
<h6>15. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION</h6>
<li>
This Policy shall be governed by laws of India. The courts at Chennai shall have exclusive jurisdiction to determine any disputes relating to this Policy.
</li>
<h6>16. CONTACT US </h6>
<li>Please contact Us regarding Your Personal Data by asking questions regarding the use or storage or processing of Your data, by emailing Us on support@dearlotteries.app. You can also get in touch with Us through Our customer service +91 8489925481. 

Our Privacy Policy was last updated on 21.12.2024 </li>
</ul>
                        </div>
                    </div>
                </div>
              
            </section>

            </div>
        );
    }
}

export default Home04;
